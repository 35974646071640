*,
*:after,
*:before {
  box-sizing: border-box;
  // font-weight: 300;
}

@media print {
  .App {
    display: none;
  }
  .noselect{
    display: none;
  }
}
