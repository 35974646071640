.sign-in-page {
  padding: 0 !important;
  background: #090763;

  .page-info__container,
  .login-form-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - var(--footer-height));
    padding: 1rem;
  }

  .page-info {
    &__container {
      position: relative;
    }

    &__logo {
      position: absolute;
      left: 1rem;
      top: 1rem;
    }

    &__heading,
    &__description {
      max-width: 55ch;
    }

    &__heading {
      margin-bottom: 1.5rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #f6fafb;
      text-transform: uppercase;
    }

    &__description {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.08px;
      color: #ffffff;
    }
  }

  .login-form-card {
    background: #fff;
    border-radius: 10px;

    .login-form-card__content-container {
      // width: 285px;
      width: 50%;
    }

    .home-menu {
      display: inline-block;
      width: 64px;
      height: 48px;
      position: absolute;
      right: 1rem;
      top: 0.9rem;
      font-size: 15px;
      font-weight: 600;
      color: #364fcc;
      cursor: pointer;
    }

    &__heading {
      margin-bottom: 3rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #364fcc;
      text-transform: uppercase;
      opacity: 0.22;
    }
  }

  .login-form {
    margin-bottom: 3rem;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;

    &__field-container {
      margin-bottom: 1.25rem;

      label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.76px;
        color: var(--color-primary-blue);
      }

      .input-text-field {
        width: 100%;
      }
    }
  }

  .link {
    font-weight: 500 !important;
    color: #0a0864 !important;
    font-size: 17px;
  }
}

.sign-in-forgot-password-modal {
  // top: 50%;
  // transform: translateY(-50%);
}

.forgot-password-modal input {
  width: 100%;
}
.aHover:hover {
  font-weight: 600;
}
.d-block-imp{
  display: block;
}
.login-btn{
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background: #090763;
  color: #fff!important;
  border: 1px solid transparent;
  border-radius: 6px;
  // box-shadow: 0 4px 16px rgb(0 0 0 / 8%);
}
.login-btn:hover{
  background: #090763;
}
.cursor{
 cursor: pointer;
}