.dashboard-page {
  padding: 0 !important;
  padding-bottom: 1rem !important;

  &-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;

    border-radius: 10px;
    background-color: #ffffff;
    border-radius: 11.2px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.07);

    &__heading {
      @include heading-styling;
      text-transform: uppercase;
    }

    &__sub-heading {
      @include sub-heading-styling;
    }

    &__content {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.25rem 0;

    .switch-option {
      margin: 0 1rem;

      &--active {
        color: var(--color-primary-blue-light);
      }
    }
  }

  .news-container {
    overflow-x: auto;

    .row {
      flex-wrap: nowrap;
      padding: 0 2rem;
    }

    .news-card {
      display: inline-block;
      // min-width: 550px;
      margin-bottom: 1rem;
    }
  }

  .dashboard-events-section {
    .event-cards__container > .row {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 1rem 0 !important;
    }

    .event-card {
      // min-height: 11rem;
    }
  }

  .map-density {
    &__container {
      display: flex;
      max-width: 350px;
    }

    &__text {
      padding: 0.25rem 1rem;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-primary-blue);
    }

    &__range {
      flex: 1;
      background: linear-gradient(90deg, #fff, #807df8);
    }
  }
  .event-card__flag-image-news{
    width: 98% !important;
    height: auto;
  }
}

.dashboard-map-section {
  .inputs-container-right {
    width: 100%;
    max-width: 650px;
  }

  .ticker-card {
    width: 100%;
    height: 100%;
  }

  .date-picker__container {
    max-width: 200px;
  }

  &__map-container {
    &__heading {
      font-size: 17px;
      font-weight: 500;
      color: var(--color-primary-blue);
    }
  }
}

.event-details-popup {
  max-width: calc(90vw - 1rem) !important;
}

.modal.fade {
  left: 3.5rem;
}
.regdashboardcard{
  color: unset!important;
  .event-card {
    display: flex;
    min-height: 8rem;
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 15px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
    border: solid 0.5px rgba(216, 216, 216, 0.65);
    background-color: #fff;
  
    &__flag-container {
      width: 64px;
      height: 48px;
    }
  
    &__flag-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
    &__content-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    &__event-name-container {
      margin-bottom: 0.5rem;
    }
  
    &__event-name {
      margin-top: 0.25rem;
      font-size: 16px;
      font-weight: 600;
      transform: translateY(-2px);
      width: 100% !important;
      bottom: -4px;
  
      @include truncate-overflow-ellipses(
        2,
        1.39rem,
        #fff,
        var(--color-primary-blue)
      );
    }
  
    &__date,
    &__event-id {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-primary-blue);
    }
  }
  
}