.news-page {
  //padding: 0;

  &__top-section {
    //padding: 2rem;
    padding-top: 1.5rem;
  }
}
.ct-toast-error{
  background-color: rgb(214, 10, 46)!important;
  color: #fff!important;
}
.ct-toast svg {
  filter: brightness(0) invert(1);
}
.news-page .site-page__header {
  margin-bottom: 0;
  //border-bottom: 1px solid rgba(19, 20, 21, 0.1);
  padding-bottom: 10px;
  padding-left: 15px;
}
.news-page .site-page__header::after {
  content: "";
  display: block;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgba(19, 20, 21, 0.1);
  margin: 10px auto;
}
.news_tag-bx {
  padding: 4px 12px;
  background: #e0dffe;
  border: 1px solid #807df8;
  border-radius: 20px;
  text-align: center;
  font-weight: normal;
  margin-right: 5px;
  margin-bottom: 3px;
}
.newsUpdates h5 a {
  color: #090763 !important;
  font-size: 16px;
  // line-height: 25px;
}
.newsButton .text-right{
  padding-right: 20px!important;
}
.newsUpdates h6 {
  font-size: 14px;
  margin-top: 6px;
}
.news-basic-info {
  color: rgba(19, 20, 21, 0.6);
  font-size: 13px;
}
.news-description {
  color: rgba(19, 20, 21, 0.6);
  font-size: 14px;
  margin-bottom: 10px;
}
.news-dot {
  --size: 7px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: rgba(19, 20, 21, 0.6);
}
.newsUpdates .card-header {
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.newsUpdates {
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.NewsModalCursor{
  cursor: auto!important;
}
.newsUpdates:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.newsCardHover:hover {
  font-weight: 600;
}
.newsAccordian {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}
.newsAccordian .card-header {
  padding: 0;
  border: none;
}
.newsAccordian .form-check-label {
  color: #131415;
  font-size: 16px;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.newsAccordian .btn {
  font-size: 15px;
  color: #555;
  font-weight: 500;
}
.newsAccordian .btn-link:hover {
  text-decoration: none;
}
.newsAccordian .card {
  border-radius: 0;
  border-right: none;
  border-left: none;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
  text-decoration: none;
}
.newsAccordian .reset-button
{
  float:right
}
.newsFilterHeading {
  color: #131415;
  padding-left: 12px;
  font-size: 17px;
  display: inline-block;
}
.chipBox {
  padding: 5px 12px !important;
  background: rgba(19, 20, 21, 0.05);
  border: 1px solid rgba(19, 20, 21, 0.1);
  border-radius: 20px;
  text-align: center;
  color: rgba(19, 20, 21, 0.6);
  margin-left: 10px;
  margin-bottom: 10px !important;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
.purpleBadge {
  background-color: #807df8;
  position: absolute;
  left: 0;
  top: 0;
  padding: 9px 9px;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 1px;
}
.filterImg {
  width: 16px;
  margin-right: 6px;
  vertical-align: bottom;
}

// ================***newsDetailModal***================
.newsModal_tag-bx {
  padding: 5px 12px !important;
  background: rgba(19, 20, 21, 0.05);
  border: 1px solid rgba(19, 20, 21, 0.1);
  border-radius: 20px;
  text-align: center;
  color: rgba(19, 20, 21, 0.6);
  margin-right: 10px;
  margin-bottom: 10px !important;
}
.newsDetailModal .tab-container__header {
  background-color: #fff;
}
.starChecked {
  color: #ffcf54;
  margin-right: 10px;
  font-size: 18px;
}
.newsDetailModal h6 {
  font-size: 14px;
}
.sourceName {
  font-size: 16px;
  color: #131415;
}
.aHover:hover {
  font-weight: 600;
}
.newsDetailModal ul {
  list-style-type: disc;
}
.newsDetailModal ul li {
  padding: 0.75rem 0;
  margin-left: 25px;
}
.newsDetailModal .card-title {
  font-size: 14px;
  color: #131415;
  line-height: 22px;
  margin-bottom: 3px;
}

.cntryHeight {
  height: 200px;
  overflow: auto;
}

.cntryHeight::-webkit-scrollbar-track {
  //  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

.cntryHeight::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}

.cntryHeight::-webkit-scrollbar-thumb {
  background-color: #ddd;
  opacity: 0.5;
}
.w-15 {
  width: 20px;
}
.newsfilterBadge {
  border-radius: 50%;
  font-size: 8px;
  padding: 3px 5px;
  text-align: center;
  margin-left: 4px;
}
.news-modal-summary{
  background-repeat: no-repeat;
  background-position:center;
  min-height: 140px;
  min-width: 120px;
  background-size: 160px 150px;
}
//Timeline
.newsAccordian .btn-filter {
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  background: #f7f7f7 !important;
  border-radius: 0;
}
.newsAccordian .btn-filter .date-range-picker__toggle-button__text {
  font-size: 16px;
  margin-left: 0;
}
// .newsAccordian .btn-filter i {
//   display: none;
// }
.newsDetailModal .newsUpdates:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
}
.similar-stories a {
  color: #131415 !important;
}
.new_stamp
{
  width: 100px;
  height: 100px;
  display: block;
}
.clrBlu
{
  color: var(--color-primary-blue);
}
//FLASH CARD CSS
.flashMsgCard
{
  cursor: pointer;
}.themesBG{
  background:#b2e6db!important;
}.tagsBG{
  background: #e0dffe;
  padding: 0.75rem 1.25rem;
}.py{
  padding-bottom: 0.7rem!important;
  padding-top: 0.7rem!important;
}
.newsThemes_tag-bx {
  padding: 5px 12px !important;
  border: 1px solid rgba(19,20,21,.25);
  border-radius: 20px;
  text-align: center;
  color: rgba(19, 20, 21, 0.6);
  margin-right: 10px;
  font-size: 13px!important;
  font-weight: 500!important;
  margin-bottom: 10px !important;
  background:#b2e6db!important;
}
//Trending News
.newsUpdates-hover:hover h5>a{
  // color :#0a79ba!important;
  color: #1a73e8!important;
}
.trendingNews{ h5>a{
  font-size: 14px!important; 
  font-weight: 600!important;
}
.news_tag-bx{
  margin: 0px 3px!important;
  font-size: 10px!important;
}
.newsUpdates{
  margin-bottom: 0.4rem!important;
} 
 h5{
  font-size: 1rem!important;
}
.newsThemes_tag-bx {
  padding: 3px 5px !important;
font-size: 11px!important;
  margin-right: 5px;
  margin-bottom: 1px !important;
  
}
.news-basic-info{
  font-size: 0.71rem!important;
}.theme-font{
  font-size: 0.75rem!important;
}

}
.newsBadge{
  white-space: normal;
}
.news-details-popup
{
  max-width: 84%;
}
// .imgcard-news .card-header-image img{
// width: 82%!important;
// height: 9.2rem;
// margin-left: 3.8rem;
// }
.imgcard-popup .card-header-image img{
  max-width: 10rem;
  margin-left: 3rem;
  height: 8rem;
}
.lastupdate{
  margin-top: 0.7rem;
  font-size: 13px;
  font-weight: 400;
}
.NewsCardImg{
  margin-left: 5rem;
  max-width: 7rem !important;
  height: 7rem;
  object-fit: cover !important;
}
.TrendingNewsImg{
  object-fit: cover;
  max-width:3.5em!important ;
  height: 3.5em  !important;
}
.summaryDiv{
  width: 100%;
}
