a {
  font-weight: 600;
  color: #807df8 !important;
}

.blue-dot {
  --size: 10px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 9999px;
  background: var(--color-primary-blue);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none!important; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.site-page {
  min-height: inherit;
  padding: 2rem;
  // opacity: 0.5;
  // animation: fade-in 450ms ease-out forwards;

  &__top-section {
    position: relative;

    .bg-world-map {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      filter: invert(93%) sepia(23%) saturate(114%) hue-rotate(192deg)
        brightness(91%) contrast(120%);

      background-image: url(../assets/images/misc/world-map.svg) !important;
      background-position: 50vw 36% !important;
      background-repeat: no-repeat !important;
      background-size: 65vw 65vw !important;
    }
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__heading {
    @include heading-styling;
    text-transform: uppercase;
  }

  &__sub-heading {
    @include sub-heading-styling;
  }
}

.MuiInputBase-input {
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0.67px !important;
  color: #a5a5a5 !important;
}

.MuiOutlinedInput-adornedStart {
  padding-left: 10px !important;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 11px;
  border: 1px solid var(--color-primary-blue);
  border-radius: 40px;
  font-size: 10px;
  color: var(--color-primary-blue);
  font-weight: 600;
}

path {
  transition: fill 1000ms ease-in-out !important;
}

// --------------------------------------
.react-switch-bg {
  background: var(--color-secondary-purple) !important;
}