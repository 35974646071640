.input-button {
  height: var(--input-height);
  padding: 0.5rem 1.5rem;

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  background: var(--color-primary-blue) !important;
  color: #fff !important;
  border: none;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  transition: box-shadow 200ms ease-in-out, filter 250ms ease-in-out,
    background 250ms ease-in-out, opacity 250ms ease-in-out;

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  }

  &.disabled {
    background: #666 !important;
    border-color: #444 !important;
    cursor:default;
  }

  &.fade {
    opacity: 0.3;
  }
}
