.svg-icon {
  --size: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--white {
    @include filter-white;
  }

  &--blue {
    @include filter-primary-blue;
  }

  &--gray {
    @include filter-gray-light;
  }

  &--purple {
    @include filter-secondary-purple;
  }

  &--purple-light {
    @include filter-secondary-purple-light;
  }

  &--gray-light {
    @include filter-gray-light;
  }
  
  // -------------------------
  &--small {
    --size: 1rem;
  }

  &--medium {
    --size: 2rem;
  }

  &--big {
    --size: 3rem;
  }
}
