.event-detail-box{
  width: 100%;
  min-height: 146px;
  margin: 25px 0 -30px;
  padding: 0.5rem 2rem;
}

.event-tl .event-date-mr {
    color: #090763;
    font-weight: 500;
    border-bottom: 3px solid #211f72 !important;
    padding-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-tl .event-des {
    padding: 15px 15px;
    font-size: 12px;
    min-height: 90px;
}

span.event-blue-circle {
    display: block;
    width: 2px;
    height: 49px;
    position: absolute;
    right: -1px;
    background: #9f9ed2;
    top: 37px;
}

span.event-blue-circle:before {
  content: '';display: block;
  width: 12px;height: 12px;
  background: #9f9ed2;
  position: absolute;
  top: -12px;
  left: -5px;
  border-radius: 50%;
}

span.event-blue-circle:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: #9f9ed2;
    position: absolute;
    bottom: -11px;
    left: -5px;
    border-radius: 50%;
}

@media (min-width: 768px){
  .event-detail-box .col-md-3{
      flex: 0 0 25%;
      max-width: 24.5%;
  }
}
.event-detail-box .read-btn:hover {
  color: #090763;
  text-decoration: underline;
}
