.site-footer {
  height: (--footer-height);
  padding: 1rem 0;
  background: #000;

  .footer-link,
  .copyright-text {
    color: gray !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    line-height: 20px;
  }

  .footer-link {
    padding: 0 0.5rem;
    border-right: 1px solid gray;
    color: gray !important;
    &:last-child {
      border-right: none;
    }
  }
}
