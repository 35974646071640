.date-range-picker {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 250px;

  &.full-width {
    max-width: unset;
  }

  .custom-filter-wrap {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9999;
  }

  .rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 1000;
    right: 0;
    width: 450px;
    max-width: 100vw;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  .hide-calendar {
    display: none;
  }

  &__toggle-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 2.5rem;
    overflow-y: hidden;
    overflow-x: auto;
    font-size: 14px !important;
    // font-weight: 500 !important;
    height: 2.4rem;
    background: #fff !important;
    border-color: hsl(0,0%,80%)!important;

    &__text{
      flex: 1;
      text-align: left;
      margin-left: 0.5rem;
    }

    &__text, &__icon{
      color: #555!important;
    }
  }
}
.section-date-range button.rdrStaticRange:nth-child(5){
  border-bottom: 0.09em solid black!important;
  padding-bottom: 10px;
}
.section-date-range button.rdrStaticRange:nth-child(6){
  padding-top: 10px;
}