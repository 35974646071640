.map-container {
  padding: 1.5rem 2rem;
  margin-top: 5px;
}
.jvectormap-container {
  cursor: pointer;
  border: 1px solid transparent;
}
.jvectormap-zoomin {
    top: 49px !important;
}
.jvectormap-zoomout {
    top: 75px !important;
}
