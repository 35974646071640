.reset-button {
  padding: 0.5rem 1rem;
  background: none !important;
  border: none !important;
  outline: none !important;
  color: var(--color-sky-blue);
}

.add-round-button,
.close-round-button {
  --size: 2.5rem;
  --color: var(--color-secondary-purple);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  color: #fff;
  font-size: 16px;
  background: var(--color);
  outline: none !important;
  border: 1px solid var(--color);
  border-radius: 555rem;
}

.close-round-button {
  --color: red;
  background: none !important;
  color: var(--color);
}

.reset-button-square {
  --size: 2.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 5px;
  outline: none !important;

  &__icon {
    color: var(--color-primary-blue) !important;
  }
}
