.aml-logo {
  &__container {
    display: inline-block;
    cursor:pointer;
  }

  &__image {
width: 15rem;
    object-fit: contain;
    @include filter-white;
  }
}
