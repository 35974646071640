.regulationDet h1 {
  font-size: 15px;
}
.resource-dot {
  --size: 7px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: rgba(19, 20, 21, 0.6);
}
.resources-page {
  padding: 25px;
  padding-bottom: 0;

}
.regulation-card{
  padding: 0.8rem;
  background: #fff;
  border: 1px solid #e2e4e5;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
 }
.ResourcePage .ticker-card {
  cursor: default;
}
.ResourcePage:after {
  content: "";
  display: block;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgba(19, 20, 21, 0.1);
  margin: 10px auto;
  z-index: 9999;
}

.news_tag-bx {
  padding: 4px 12px;
  background: #e0dffe;
  border: 1px solid #807df8;
  border-radius: 20px;
  text-align: center;
  font-weight: normal;
  margin-right: 5px;
  margin-bottom: 3px;
}
.resourceCard h5 {
  color: #090763 !important;
  font-size: 15px;
  line-height: 25px;
}
.news-basic-info {
  color: rgba(19, 20, 21, 0.6);
  font-size: 13px;
}
.news-description {
  color: rgba(19, 20, 21, 0.6);
  font-size: 14px;
  margin-bottom: 10px;
}
.news-dot {
  --size: 7px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: rgba(19, 20, 21, 0.6);
}
//RESOURCE DETAIL CSS
.ViewSDK_parentRelativeHeight {
  height: 100%;
  min-height: 670px;
}
.regulationDet h5 {
  color: #090763 !important;
  font-size: 18px;
  line-height: 25px;
}
.detailSource h6 {
  // margin-top: 20px;
  font-size: 15px;
  color: #9898a7 !important;
  display: flex;
  align-items: center;
}


.detailSource a{
  overflow-wrap: break-word;
}
.detailSource a:hover{
  font-weight: 600!important;
}
 .resource-detail a:hover{
  font-weight: 400t;
}
.backToReg {
  font-size: 13px;
  text-decoration: underline;
  color: #514fd4!important;
  font-weight: 600!important;
}
.backToReg:hover{
  font-size: 13px!important;
  font-weight: 600!important;
}
.bg-pdf
{height: fit-content;
  background-color: #eaeaea;
}.height{
  // overflow: hidden;
  height:100vh;
}.pdfDescription{
  font-size: 12px;
}
.regulationCategoryTag {

  .tag-bx {
    padding: 5px 0;
    // padding: 0 2rem;
    // background: #fff;
    a {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      padding: 3px 11px;
      border: 2px solid #090763;
      border-radius: 10px;
      font-size: 13px;
      color: #090763 !important;
      font-weight: 600;
      margin-top: 3px;
      margin-bottom: 5px;
      cursor: unset;
    }
  }
}
.regulSubheading{
  span{
    font-size: 14px;
    font-weight: 600;
    opacity: 0.75;
  }
 
}

.issueAuthority{
  h6{
    color: #090763;
    font-size: 0.88rem;
  }
  .authorityDescription{
    font-size: 13.5px;
    line-height: 1.6;
}
  }
  .baseRegulHeading{
  
      h6{
        color: #090763;
        font-weight: 600;
        font-size: 0.93rem;
      }
 
  }
.regulList{
  margin: 0.8rem 0rem;
  ul{
    line-height: normal;
    list-style: disc!important;
    padding-left:15px;
  }
::marker{
  color: #090763;
}
  ul li{
    margin-left: 3px;
    margin-bottom: 0.25rem;
  }
  .listItems{
    color: #090763;
    font-size: 13px;
    white-space: inherit;
    font-weight: 400;

  }
}.regulSubheading2{
  span{
    font-size: 14px;
    font-weight: 600;
    opacity: 0.75;
  }
}

.reguldiv{
  .tag-bx {
    padding: 5px 0;
    // padding: 0 2rem;
    // background: #fff;
    a {
      background-color: rgb(224 ,223, 254);
      display: inline-block;
      vertical-align: middle;
      margin-right: 13px;
      padding: 5px 12px;
      // border: 1.5px solid #090763;
      border-radius: 40px;
      font-size: 13px;
      color: #090763 !important;
      font-weight: 400;
      margin-top: 4px;
      margin-bottom: 4px;
      white-space: normal;
      cursor: unset;
      overflow-wrap: break-word;
  }
    }
  
}

.mt-2rem{
  margin-top: 2rem;
}

.sections{
  padding: 5px 15px;
  margin: 5px 0px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
}


/* Timeline */
.RegTimeline {
  border-left: 4px solid #090763;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #090763;
  letter-spacing: 0.5px;
  position: relative;
  font-size: 1.03em;
  list-style: none;
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
}

.RegTimeline .event {
padding-bottom: 17px;
position: relative;
}
.RegTimeline .event:last-of-type {
padding-bottom: 0;
margin-bottom: 0;
border: none;
}
.RegTimeline .event:before,
.RegTimeline .event:after {
position: absolute;
display: block;
top: 0;
}
.RegTimeline .event:before {
left: -217.5px;
color: rgba(255, 255, 255, 0.4);
content: attr(data-date);
min-width: 120px;
}
.RegTimeline .event:after {
  box-shadow: 0 0 0 2.5px #090763;
  left: -5.85px;
  background: #ffffff;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  content: "";
  top: 5px;
}
.RegTimeline{
  .event{
    p{
      overflow-wrap: break-word!important;
      font-size: 14px;
      font-weight: 450;
      margin-left: 15px;
    }
  }
}
.keywordsDiv{
  .tag-bx {
    padding: 5px 0px;
    // padding: 0 2rem;
    // background: #fff;
    a {
      background-color: rgb(224 ,223, 254);
      display: inline-block;
      vertical-align: middle;
      margin-right: 13px;
      padding: 5px 8px;
      // border: 1.5px solid #090763;
      font-weight: 400;
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 13px;
      color: #090763 !important;
      font-weight: 400;
      white-space: normal;
      cursor: unset;
      overflow-wrap: break-word;
    }
}
}
.my-32{
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}
.listItemsNull{
  font-size: 20px;
  font-weight: 600;
}
.listItemsNullimp{
  font-size: 20px!important;
  font-weight: 600!important;
  padding-left: 15px;
}
.overflow-x-hidden{
  overflow: hidden;
.card-header{
  padding: 0.3rem 0.5rem;
  background-color: #f9f9fb;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
  i{
    float: right;
    opacity: 0.55;
    font-size: 13px;
    font-weight: 300;
  }
}
.b-none{
  border: none;
}
.card{
  border: none;
}
}