.tab-container {
    &__header {
        // padding: 0 1rem;
        border-bottom: 1px solid #d8d8d8;

        .tab-name {
            display: inline-block;
            padding: 0.5rem 1.5rem;
            cursor: pointer;
            color: #999999;
            border-bottom: 3px solid transparent;
            transition: color 150ms ease-in-out;

            &.active {
                color: var(--color-primary-blue);
                border-color: var(--color-secondary-purple);
            }
        }
    }

    &__content {
        padding: 1.5rem;
    }
}