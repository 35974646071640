.ticker-card {
  --card-bg: #fff;
  --text-color: var(--color-primary-blue);
  --icon-color: #fff;
  --icon-bg-color: #a395f9;

  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background: var(--card-bg);
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  transition: background 250ms ease-in-out;
  cursor: pointer;
  width: 250px;
  max-width: 100%;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    margin-right: 0.5rem;
    color: var(--text-color);
    font-weight: 500;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 1.4rem;
    transform: scale(1.18);

    .font-icon {
      // background: var(--card-bg);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: var(--icon-color);
      padding: 0.25rem;
      border-radius: 555rem;
      background: var(--icon-bg-color);
      --size: 1.35rem;
      width: var(--size);
      height: var(--size);
      min-width: var(--size);
      min-height: var(--size);
    }
  }

  &__icon:not(.ticker-card__icon--font-icon) {
    @include filter-secondary-purple;
    position: relative;
    top: -3px;
  }

  &__value {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1.1px;
    color: var(--text-color);
  }

  &__title,
  &__value {
    text-align: left;
  }

  &--active {
    --card-bg: var(--color-secondary-purple);
    --text-color: #fff;
    --icon-color: var(--color-secondary-purple);

    .ticker-card__icon--active:not(.ticker-card__icon--font-icon) {
      @include filter-white;
    }

    .font-icon {
      background: #fff;
    }
  }
}

.event-details-popup {
  .ticker-card{
    &__title {
     margin-right: 0 !important;
    }

    &__value {
    font-size: 20px !important;
    }
  }
}
