.countries-page__top-section {
  padding: 1.5rem 2rem 2rem;
  background: #fff;
}

.countries-page {
  padding: 0;
  margin-top: .5rem;

  &__top-section {
    padding: 2rem;
    padding-top: 1.5rem;
    background: #fff;
  }

  .input-fields-container {
    max-width: 650px;
  }

  .search-padding {
    padding-left: 75px;
    padding-right: 600px;
  }

  .countries-container {
    padding: 0 1rem;
  }

  .country-details-link {
    display: block;
    color: inherit !important;
    height: 100%;
  }

  .country-details-link :hover {
    font-weight: 600;
  }

  .country-card {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 350px;
    max-width: 100%;
    height: 100%;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 250ms ease-out forwards;

    cursor: pointer;
    font-size: 14px;
    text-align: center;
    border: solid 0.5px #ebebeb;
    border-radius: 14.4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;

    &__flag-container {
      width: 5rem;
      height: 4rem;
    }

    &__flag-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}