.country-detail-page {
  padding: 0;
  margin-top: 0.5rem;

  &__top-section {
    padding: 2rem;
    padding-top: 1.5rem;
    background: #fff;
  }
  .resultsCount{
    font-size: 14px!important;
  }
  .event-uid-block {
    font-weight: 500;
    color: var(--color-primary-blue) !important;
    cursor: pointer !important;
  }

  .bg-world-map {
    background-position: 50vw -13vw !important;
    background-size: 46vw 46vw !important;
  }

  .tab-container {
    &__header {
      background: #fff;
    }

    &__content {
      & > .events-tab-content {
        margin: 0 -1.5rem;

        .events-table {
          tr {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .country-flag {
    &__container {
      display: inline-flex;
      align-items: flex-start;
      width: 5rem;
      height: 4rem;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__tickers-container {
    @media (min-width: 992px) {
      .col-lg-2 {
        flex: 0 0 20% !important;
        max-width: 20% !important;
      }
    }

    @media (min-width: 1500px) {
      .col-xl-auto {
        flex: 0 0 auto !important;
        width: auto !important;
        max-width: 100% !important;
      }
    }
  }

  .ticker-card {
    height: 100%;
    cursor: unset !important;
  }

  .country-detail-card {
    padding: 1.5rem;
    border-radius: 15px;
    border: solid 0.5px #e8e8e8;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    height: 100%;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;
  }

  .country-details-p {
    // max-width: 65ch;
    // color: var(--color-primary-blue);
  }

  .detail-field {
    display: flex;
    justify-content: space-between;
    padding: 0.45rem 1rem;
    border-bottom: solid 1px #9797972e;

    &__name,
    &__value {
      font-size: 14px;
    }

    &__name {
      color: var(--color-primary-blue-light);
    }

    &__value {
      color: var(--color-primary-blue);
      text-align: right;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
  .events-table {
    tbody {
      tr {
        cursor: pointer;
        margin-top: 1rem;
        background: #fff;
        border: solid 0.5px rgba(216, 216, 216, 0.65);
        border-radius: 5px;
        box-shadow: 0 2px 4 0 rgba(0, 0, 0, 0.06);
        transition: box-shadow 250ms ease-in-out;

        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .case-summary-block {
      @include truncate-overflow-ellipses(
        3,
        1.33rem,
        #fff,
        var(--color-primary-blue)
      );
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;

    border-radius: 10px;
    background-color: #ffffff;
    border-radius: 11.2px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.07);

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;

    &__heading {
      @include heading-styling;
      text-transform: uppercase;
    }

    &__sub-heading {
      @include sub-heading-styling;
    }

    &__content {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.25rem 0;

    .switch-option {
      margin: 0 1rem;

      &--active {
        color: var(--color-primary-blue-light);
      }
    }
  }

  .country-detail-regulatory-section {
    .tab-container {
      &__header {
        display: flex;
      }

      &__content {
        padding: 0 !important;
        padding-top: 0.5rem !important;
      }

      .tab-name {
        flex: 1;
        color: var(--color-primary-blue);

        &.active {
          color: #fff !important;
          background: var(--color-primary-blue);
          border: none !important;
        }
      }
    }
  }

  .country-indice-card {
    padding: 1.5rem;
    border-radius: 15px;
    border: solid 0.5px #e8e8e8;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    height: 100%;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;

    &__name {
      font-size: 15px;
      font-weight: 700;
    }

    &__rank {
      font-size: 13px;
      font-weight: 500;

      color: var(--color-secondary-purple);
    }

    &__info {
      font-size: 13px;
      // max-width: 55ch;
      text-align: center;
    }

    &__graph-container {
      border: 1px solid rgba(0, 0, 0, 0.03);
    }

    &__table-container {
      td,
      th {
        font-size: 14px;
        padding: 0.25 1rem;
      }
    }
  }

  .country-economic-table {
    td,
    th {
      font-size: 14px;
      padding: 0.25 1rem;
    }

    thead {
      tr {
        background: #e9e9f1;
        color: var(--color-primary-blue);
        font-size: 15px;
        font-weight: 500;
      }
    }

    tbody {
      td {
        font-weight: 400;
      }
    }
  }
}
