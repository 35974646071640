.site-table {
  width: 100%;

  thead {
    tr {
      background: rgb(233, 233, 241);
    }

    td {
      color: var(--color-primary-blue);
      font-size: 15px;
      font-weight: 500;
    }
  }

  tbody {
    // overflow-x: auto;

    tr {
      opacity: 0;
      transform: translateY(8px);
      animation: fade-in-rise-up 350ms ease-out forwards;
    }

    td {
      color: #444;
      font-size: 14px;
      font-weight: 300;
    }
  }

  tr {
    display: flex;
    align-items: center;
    padding: 1rem 0;
  }

  td {
    padding: 0 0.75rem;
  }

  tbody>tr>:nth-child(4){
   text-align:center;
  }

  .link-td {
    display: block;
    color: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
  }

  img.event-img {
    width: 45%;
    height: auto;
  }
}
