.search-input-field {
  &__container {
    position: relative;
    padding: 0.45rem 0.25rem 0.45rem 2rem;
    border-radius: 10px;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #ebebeb;
    background-color: #ffffff;
  }

  &__search-icon {
    position: absolute;
    left: 0.45rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input {
    width: -webkit-fill-available;
    color: #121212;
    font-size: 14px;
    font-weight: 600;
    border: none !important;
    outline: none !important;
  }
}
