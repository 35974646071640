/* Common Css Start */

.font-size-12 {
  font-size: 12px;
}
.font-size-12pt5 {
  font-size: 12.5px;
}
.font-size-13 {
  font-size: 13px!important;
}
.font-size-14 {
  font-size: 14px;
}
.hovera:hover{
  font-size: 14px;
  font-weight: 600;
}
.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

/* Insight Page Css Start */
.nav-list {
  padding-top: 45px;
  padding-bottom: 20px;
}
.nav-list ul {
  list-style: none;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
}
#resources .country-details-link:hover{
  font-size: 13px!important;

}

.nav-list ul li a {
  display: inline-block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #999999 !important;
  border-bottom: 3px solid transparent;
  transition: color 150ms ease-in-out;
  font-weight: normal;
  font-size: 14px;
}

.nav-list ul li {
  display: inline-block;
  padding: 0.3rem 1rem;
  cursor: pointer;
  color: #353333;
  font-size: 14px;
}
.nav-list li.active a {
  color: #070763 !important;
}
.nav-list li.active {
  border-bottom: 3px solid #070763;

  text-align: center;
  color: #070763;
  font-size: 14px;
}

.lg-card .card-text {
  font-weight: 600;
  line-height: 1.27;

  letter-spacing: -0.24px;
  text-align: center;
  color: #2b292d;
}

.lg-card .card-pera {
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #a0acba;
}
.lg-card1{
  margin: 0px auto;
  width: 375px;
  .card-pera1 {
    font-weight: 600;
    line-height: 1.25;
    color: #a0acba;
    text-align: justify!important;
  }
}

.lg-card .card-body {
  /* padding: 20px 25px !important; */
  padding: 20px 12px !important;
}

.lg-card .card-img-top {
  border-radius: 10px;
}

.lg-card:hover .card-img-top {
  opacity: 0.3;
}

.lr-btn-block {
  display: block;
}

.lr-btn {
  padding: 8px 20px;
  margin-top: 35px;
}

.vi-tab .vi-img {
  width: 275px;
  height: 190px;
  border-radius: 15px;
}

.vi-tab .card .card-body {
  padding: 1.25rem;
}

.vi-tab .text-left {
  padding-left: 0;
}

.vi-card-bold {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  color: #090763;
}

.vi-card-pera {
  font-weight: 500;
  line-height: 1.75;
  color: #666666;
}

.vi-rm-btn {
  font-weight: 600;
  color: #070763;
}

.vi-rm-btn:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #070763;
}

/* Insight Page Css End */
.main-layout .site-navigation-panel {
  z-index: 999;
}
.ril__image {
  width: 50%;
}
.fixedElement {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.mt-150 {
  margin-top: 190px;
}
.insight_card
{
  border-radius: 14.8px;
    box-shadow: 0 12px 13px 0 rgba(0, 0, 0 ,0.05);
    border: .5px solid rgba(205,208,227,.65);
    background-color: #fff;
}
.other-updates{
  .plan-tab {
    // padding-top: 45px;
    // padding-bottom: 20px;
    text-align: center;
    // margin-left: 19rem;
  }
  
  .plan-tab ul {
    list-style: none;
  }
  
   .plan-tab li {
    display: inline-block;
    padding: 4px 1.5rem;
    cursor: pointer;
    padding: 8px 18px;
  }
  
   .plan-tab li.active {
     color: #070763;
     border-bottom: 1px solid #070763;
  }

  .plan-tab ul li.active:first-child a{
    color: white !important;
 }

  
   nav.plan-tab.mbt-35 {
    text-align: center;
  }
  
   .plan-tab ul {
      list-style: none;
      margin: 0 0 25px 0px;
      padding: 0;
      display: inline-block;
  }
  
   .plan-tab ul li {
      float: left;
      border: 1px solid #070763;
      font-size: 12px;
      font-weight: bold;
      // color: #211f72;
  }
  .plan-tab li a{
    color:  #211f72!important;
  }
 

  .plan-tab li.active a{
    color: white !important;
  }
  
   .plan-tab ul li:first-child {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-right: none;
  }
  
  .plan-tab ul li:last-child {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
  }
  
   .plan-tab li.active {
      color: #fff;
      background: #070763;
      border: 1px solid #070763;
      padding: 8px 18px;
  }
  
}
// Blog
// .blog-div{
//     border-radius: 0.5rem!important;
//     border: 1px solid rgba(0, 0, 0, 0.255);
//     display: flex;
//   }
//   .blog-image{
//     border-top-left-radius: 0.5rem!important;
//     border-top-right-radius: 0.5rem!important;
//     padding: 0.25rem;
//   }

//   .blog-card a{
//     color:inherit!important;
//     display: flex;
//     width: 18rem!important;
//     border-radius: 0.5rem!important;
//   }
//   .blog-card{
//     display: flex!important;
//   }
//   .blog-card a:hover{
//     box-shadow: 0 2px 12px 0  rgba(0, 0, 0, 0.255);;
//   }
//   .blog-date{
//     background-color: #fff;
//   }
 
  // img.card-img-top{
  //   border-top-left-radius: 20px!important;
  //   border-top-right-radius: 20px!important;
  // }

  
//spotlight
.spotlight-div{
border-radius: 10px!important;
}
.card-pera1{
  p{
    text-align: justify!important;
    // word-break: break-all;
    font-size: 12.5px !important;
    line-height: normal!important;
    margin-bottom:10px ;
    word-spacing: -1.5px;
  }
  strong{
    text-align: justify!important;
    word-break: break-all;
    font-weight: bolder;
  }
}
@media (max-width: 1245px){
  .mt-150 {
    margin-top: 250px;
}
.lg-card1{
  width: auto;
}
}
.home-insight-card {
  width: 235px !important;
  min-height: 361px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 10px;
  background-blend-mode: overlay, normal;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.home-insight-card .card-body {
  padding: 15px 15px !important;
}

.home-insight-card .card-pera {
   font-weight: 600;
   line-height: 1.25;
   text-align: center;
   color: #a0acba;
}

.home-insight-card .card-text {
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: #2b292d;
  padding-top: 10px;
}

.home-insight-card .card-img-top {
  border-radius: 10px;
}
.home-insight-card {
    width: 100%!important;
}
.blog-detail-image .img-fluid{
  width: 37rem!important;
  height: 37rem!important;
}
.blog-content-div{
  background: #fff!important;
}
.blog-image-div{
margin: 3% 0%;
}
.BlogShare{
  padding-right: 0.5rem;
  display: grid!important;
}
.blog-content{
margin-right: 3.8em;
  margin-bottom: 3em;
}
.blog-content p{
  margin: 1.5rem 0rem;
}
.blog-content strong{
  color: #182026;
}
.blogShareIcons .fa
{
  color: #090763;
  font-size: 25px;

}
.blogShareIcons .fa-link{
  cursor: pointer;
}

.back-icon{
  color: #090763;
  font-size: 1.031rem;
  cursor: pointer;
}
.back-icon span{
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.9rem;
}
.padding-top{
  padding-top: 5rem;
}
.padding-right{
  padding-right: 1.9rem!important;
}
.margin-top {
  margin-top: 20rem;
}
/*blog card*/
.blog-card .blog-card-content{
  padding-top :1.25rem!important;
  padding-bottom: 1.25rem!important;
  padding-left: 0.5rem!important;
  padding-right: 0.5rem!important;
}
.blog-card .blog-image{
margin-bottom: 1rem;
height:10.8rem
}
.blog-card .blog-title{
  font-size: 17px;
}
.blog-card .blog-desc{
  font-size: 14px;
}
.blog-card .link-blog{
  text-decoration: none!important;
}
.CopyBtn{
  height: 23px;
  width: 23px;
}
.date-font{
  font-size: 1rem;
}
@media(min-width:1200px){
  .container-blog-custom{
    max-width: 1400px!important;
  }
}
.purpleBadge2 {
  background-color:  #090763;
  position: absolute;
  top: 3px;
  left: 5px;
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}