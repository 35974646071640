.notFoundHeading{
    font-size: 13.5rem;
    color:var(--color-primary-blue);
    font-weight: bolder;
}
.notFoundSubheading{
    font-size: 17.5px;
    span{
        font-weight: 700;
        font-size: 20px;
        color:var(--color-primary-blue);
    }
}
#notFound{
.backButton:hover{
    font-size: 14px!important;
        font-weight: 500!important;
    }
}
.mt6rem{
    margin-top: 6rem;
}