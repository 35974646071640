.datasets{
    .py-2pt5{
       padding-top: 2.5rem !important;
       padding-bottom: 2.5rem !important;
    }
    .container-da{
        padding-left:1em ;
        padding-right: 1em;
    }
    overflow-x: hidden;
    .insight_card{
        box-shadow: none;
        // min-height: 22rem;
     
    }
    .insight_card:hover{
        border: 1px solid #3A3982;
    }
    .da-body {
        p{
            font-size: 13px;
            line-height: 1.75;
        }
    }
  .DA-image{
      
      img{
        width: 3.3rem;
      }
  }
  .DA-icon{
      background-color: #E0DFFE;
      border-radius: 12px;
      width:  4rem;
      height: 3.5rem;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      i{
          color:#090763 ;
          font-size: 30px
    
      }
      img{
        width: 1.8rem;
      }
  }
  .vi-card-bold{
      font-size: 17px;
  }
  .tag-bx {
    padding: 5px 0;
    // padding: 0 2rem;
    // background: #fff;
    a {
        display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    padding: 5px 10px;
    border: 1px solid #807DF8;
    border-radius: 40px;
    font-size: 12px;
    color: #807DF8 !important;
    font-weight: 600;
    margin-top: 9px;
    margin-bottom: 9px;
    cursor: unset;
    }
  }
  @media (min-device-width :1200px){
      .col-xl-9{
          padding-left: 0px!important;
      }
      .col-xl-3{
        padding-right: 0px!important;
    }
   
  }
}