
.verificationpage {
  padding: 0 !important;
  background: #090763;

  .page-info__container,
  .sign-up-form-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - var(--footer-height));
    padding: 1rem;
  }

  .page-info {
    &__container {
      position: relative;
    }

    &__logo {
      position: absolute;
      left: 1rem;
      top: 1rem;
    }

    &__heading,
    &__description {
      max-width: 55ch;
    }

    &__heading {
      margin-bottom: 1.5rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #f6fafb;
      text-transform: uppercase;
    }

    &__description {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.08px;
      color: #ffffff;
    }
  }

  .sign-up-form-card {
    background: #fff;
    border-radius: 10px;


    .sign-up-form-card__content-container{
        // width: 285px;
        width: 50%;
    }


    &__heading {
      margin-bottom: 2rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #364fcc;
      text-transform: uppercase;
      opacity: 0.22;
    }
  }

  .sign-up-form {
    margin-bottom: 2rem;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;

    &__field-container {
      margin-bottom: 0.5rem;

      label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.76px;
        color: var(--color-primary-blue);
      }

      .input-text-field,
      .input-button {
        width: 100%;
      }

      .input-button {
        margin-top: 1.5rem;
      }
    }

    &__field-container {
      opacity: 0;
      transform: translateY(8px);
      animation: fade-in-rise-up 400ms ease-out forwards;

      &--email-field{
        position: relative;
        z-index: 500;
      }
    }
  }

  .link {
    font-weight: 500 !important;
  }
}

.page-section{
    // background-color: #364fcc;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 122px );
    height: 100%;
    width: 100%;
    // padding: 50px;
  }
  .page-section p{
    // color: #999;
    color: #090763;
    font-weight: normal;
  }
  .page-section a{
    color: #364fcc;
    text-decoration: none;
    font-weight: normal;
  }
  .page-section .loginto{
    color: #999;
  }
  .page-inner{
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    padding: 50px;
    box-shadow: 5px 5px 30px rgba(0,0,0,0.3); 
  }
  .header-sec{
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
  }
  .header-sec .left{
    text-align: right;
  }
  .page-content{
    padding: 30px 0;
  }
  .page-content h1{
    font-size: 38px;
    margin-bottom: 20px;
    color: #090763;
  }
  .content-bottom{
    border-top: 1px solid #eee;
    padding-top: 15px;
    text-align: center;
  }
  .brand img{
    max-width: 200px;
    image-rendering: -webkit-optimize-contrast;
  }