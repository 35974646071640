.developer-guide-page {
    .component-info {
      padding: 2rem;
      margin-bottom: 2rem;
      background:  rgba(0, 0, 0, 0.04);
      border: 1px dashed rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      height: calc(100% - 1rem);
  
      // &__name {
      // }
  
      &__usage {
        margin-top: 1rem;
        &-code {
          display: block;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          overflow-x: auto;
          width: 100%;
        }
      }
  
      &__component {
        margin-top: 1rem;
      }
    }
  }
  