.profile-image-uploader {
  --size: 5rem;
  position: relative;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  border: 1px solid var(--color-primary-blue);
  border-radius: 555rem;
  background: #fff;

  .profile-image-uploader__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 555rem;
  }

  .default-image-container__icon {
    --size: 1.5rem;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    background: var(--color-primary-blue);
    bottom: 0;
    right: 0;
    font-size: 10px;
    color: #fff;
    border-radius: 555rem;
  }

  &__input {
    display: none;
  }

  &__loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.profile-image-cursor{
  cursor: default!important;
}
