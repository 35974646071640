.main-layout {
  --site-navigation-panel-width: 5.5rem;
  --site-navigation-panel-width-hover: 12rem;
  --app-header-height: 5rem;
  --footer-height: 3.5rem;

  background: var(--color-gray-light);

  .site-navigation-panel {
    position: fixed;
    z-index: var(--z-index-site-navigation-panel);
    top: 0;
    left: 0;
    height: 100vh;
    width: var(--site-navigation-panel-width);

    cursor: pointer;
    // background: var(--color-fg-primary);
    background: var(--color-primary-blue);
    transition: width 200ms ease-in-out;

    &:hover,
    &.is-locked {
      width: var(--site-navigation-panel-width-hover) !important;
      transition: width 250ms ease-in-out;

      & ~ .main-layout__main,
      & ~ .site-footer {
        margin-left: var(--site-navigation-panel-width-hover) !important;
      }
     }

    &.is-locked {
      & ~ .main-layout__main,
      & ~ .site-footer {
        margin-left: var(--site-navigation-panel-width-hover) !important;
      }
    }
  }

  &__main,
  .site-footer {
    margin-left: var(--site-navigation-panel-width);
    transition: margin-left 400ms ease-in-out;
  }

  &__main {
    // min-height: 100vh;
    min-height: calc(100vh - var(--footer-height));
    overflow: auto;
  }  
  .site-navigation-panel{
    pointer-events: auto;
    .nav-link__title{
          pointer-events: none;
    }
  } 
  
}
