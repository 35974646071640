.profile-edit-page {
  .profile-edit-form-card {
    max-width: 1200px;
    // margin: 0 auto;
    // padding: 1rem;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
    // border: 1px solid #e2e4e5;
    // border-radius: 10px;
  }

  .input-field {
    margin-top: 1.5rem;

    &__label {
      display: block;
      padding-left: 0.25rem;
      font-size: 14px;
      color: var(--color-primary-blue-light);
    }

    .input-text-field {
      width: 100%;
    }
  }

  .submit-button {
    max-width: 200px !important;
  }

  .admin-basic-info-container {
    .admin {
      &__value-label {
        font-size: 24px;
        font-weight: 600;
        color: #131415;

        &--small {
          font-size: 16px;
          font-weight: 500;
          color: #131415;
        }
      }
    }
  }

  .profile-completion-percentage-container {
    max-width: 350px;
    display: flex;
    align-items: center;

    &__bar {
      flex: 1;
    }
  }
}
