.sign-up-page {
  padding: 0 !important;
  background:#090763;

  .page-info__container,
  .sign-up-form-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - var(--footer-height));
    padding: 1rem;
  }

  .page-info {
    &__container {
      position: relative;
    }

    &__logo {
      position: absolute;
      left: 1rem;
      top: 1rem;
    }

    &__heading,
    &__description {
      max-width: 55ch;
    }

    &__heading {
      margin-bottom: 1.5rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #f6fafb;
      text-transform: uppercase;
    }

    &__description {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.08px;
      color: #ffffff;
    }
  }

  .sign-up-form-card {
    background: #fff;
    border-radius: 10px;

    .sign-up-form-card__content-container {
      // width: 285px;
      width: 50%;
    }

    &__heading {
      margin-bottom: 2rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #364fcc;
      text-transform: uppercase;
      opacity: 0.22;
    }
  }

  .sign-up-form {
    margin-bottom: 2rem;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;

    &__field-container {
      margin-bottom: 0.5rem;

      label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.76px;
        color: var(--color-primary-blue);
      }

      .input-text-field,
      .input-button {
        width: 100%;
      }

      .input-button {
        margin-top: 1.5rem;
      }
    }

    &__field-container {
      opacity: 0;
      transform: translateY(8px);
      animation: fade-in-rise-up 400ms ease-out forwards;

      &--email-field {
        position: relative;
        z-index: 500;
      }
    }
  }

  .link {
    font-weight: 500 !important;
    color: #0a0864 !important;
    font-size: 17px;
  }
}
