.full-page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  opacity: 0;
  transform: translateY(8px);
  animation: fade-in-rise-up 400ms ease-out forwards;
}
