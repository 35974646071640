.events-page {
  padding: 0;

  &__top-section {
    padding: 2rem;
    padding-top: 1.5rem;
    background: #fff;
  }
.resultsCount{
  font-size: 14px!important;
}
  .events-search-input-field {
    max-width: unset;
  }

  .inputs-container {
    max-width: 1020px;
  }

  .filter-container {
    width: 100%;
  }

  .events-table {
    tr {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .event-uid-block,
    .country-name-block {
      font-weight: 500;
      color: var(--color-primary-blue) !important;
      cursor: pointer !important;
    }

    .case-summary-block,
    .reasoning-block .event-name-block {
      @include truncate-overflow-ellipses(
        4,
        1.31rem,
        #fff,
        var(--color-primary-blue)
      );
    }

    .case-summary-block {
      &:before {
        right: 2.5rem !important;
        bottom: 1px;
        pointer-events: none !important;
      }

      &::after {
        right: 1.1rem !important;
        pointer-events: none !important;
        width: 2rem;
      }
    }

    tbody {
      tr {
        margin-top: 1rem;
        background: #fff;
        border: solid 0.5px rgba(216, 216, 216, 0.65);
        border-radius: 5px;
        box-shadow: 0 2px 4 0 rgba(0, 0, 0, 0.06);
        transition: box-shadow 250ms ease-in-out;

        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        }
      }

      td {
        // color: var(--color-primary-blue);
        color: #333;
      }
    }
  }
}
.top-section-override{
  padding-left: 2rem!important;
  padding-left: 2rem!important;
  padding-top: 0rem!important;
  padding-bottom: 0rem!important;
  background: none!important;
}