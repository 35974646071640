.news-card {
  height: min-content;
  min-height: 8rem;
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 15px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
  border: solid 0.5px rgba(216, 216, 216, 0.65);
  background-color: #fff;

  &__title {
    font-size: 17px;
    color: var(--color-primary-blue);
    width: 100% !important;

    @include truncate-overflow-ellipses(
      1,
      1.33rem,
      #fff,
      var(--color-primary-blue)
    );
  }

  &__date {
    display: block;
  }

  &__content-container {
    display: flex;
    margin-top: 0.5rem;
  }

  &__image-container {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.09);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {
    flex: 1;
    margin-left: 1rem;

    @include truncate-overflow-ellipses(
      3,
      1.39rem,
      #fff,
      var(--color-primary-blue)
    );
  }
}
