.event-card {
  display: flex;
  min-height: 8rem;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 15px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
  border: solid 0.5px rgba(216, 216, 216, 0.65);
  background-color: #fff;

  &__flag-container {
    width: 64px;
    height: 48px;
  }

  &__flag-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__event-name-container {
    margin-bottom: 0.5rem;
  }

  &__event-name {
    margin-top: 0.25rem;
    font-size: 16px;
    font-weight: 600;
    transform: translateY(-2px);
    width: 100% !important;
    bottom: -4px;

    @include truncate-overflow-ellipses(
      2,
      1.39rem,
      #fff,
      var(--color-primary-blue)
    );
  }

  &__date,
  &__event-id {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-primary-blue);
  }
}
