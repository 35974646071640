.reportsPdf
{
    height: 600px;
    border: 1px solid #a9a9a9;
    margin-bottom: 70px;
}
.reportHeading
{
    font-weight: 700;
    color: var(--color-primary-blue);
    font-size: 32px;
}
.reportSubHeading {
    color: var(--color-primary-blue);
    font-size: 19px;
    font-weight: bolder;
    letter-spacing: -0.13px;
    // text-transform: uppercase;
}
.report-container
{
    font-size: 14px;
    text-align: justify;
}
.reportShareIcons .fa
{
    color: #090763;
    font-size: 25px;
    margin-left: 7px;
}
.pdf-container{
    height: 50rem;
    width: 45rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .reportsPdf-square
{
    height: 600px;
    // border: 1px solid #a9a9a9;
    margin-bottom: 70px;
}
@media screen  and (min-width :1300px){
    .reportsPdf-square{
        margin-left: 11.5rem;
        margin-right: 11.5rem;
    }
}
@media screen  and (min-width :768px ) and (max-width:1299px){
    .reportsPdf-square{
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

  .downloadicon{
    height: 25px;
    width: 28px;
  }