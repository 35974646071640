*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

a,
a:hover,
a:active,
a:visited {
  display: unset;
  color: unset;
  font-size: unset;
  font-weight: unset;
  text-decoration: none;
}

p {
  margin: 0;
}

.MuiFormControl {
  &-root {
    width: 100%;
  }

  &-marginNormal {
    margin: 0 !important;
  }
}
