.profile_site-page
{
  padding: 2rem;
  .admin {
    &__organization-name {
      font-size: 24px;
      font-weight: 600;
      color: #131415;
    }
  
    &__name {
      font-size: 16px;
      font-weight: 500;
      color: #131415;
    }
  }
  
  .ticker-card {
    height: 100%;
    cursor: initial !important;
  }
}

.profile-page {
 
  .plan-tab {
    // padding-top: 45px;
    // padding-bottom: 20px;
    text-align: center;
    // margin-left: 19rem;
  }
  
  .plan-tab ul {
    list-style: none;
  }
  
   .plan-tab li {
    display: inline-block;
    padding: 4px 1.5rem;
    cursor: pointer;
    padding: 8px 18px;
  }
  
   .plan-tab li.active {
     color: #070763;
     border-bottom: 1px solid #070763;
  }

  .plan-tab ul li.active:first-child a{
    color: white !important;
 }

  
   nav.plan-tab.mbt-35 {
    text-align: center;
  }
  
   .plan-tab ul {
      list-style: none;
      margin: 0 0 25px 0px;
      padding: 0;
      display: inline-block;
  }
  
   .plan-tab ul li {
      float: left;
      border: 1px solid #070763;
      font-size: 12px;
      font-weight: bold;
      // color: #211f72;
  }
  .plan-tab li a{
    color:  #211f72!important;
  }
 

  .plan-tab li.active a{
    color: white !important;
  }
  
   .plan-tab ul li:first-child {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-right: none;
  }
  
  .plan-tab ul li:last-child {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
  }
  
   .plan-tab li.active {
      color: #fff;
      background: #070763;
      border: 1px solid #070763;
      padding: 8px 18px;
  }
  

  .mt-25{
    margin-top:25px;
    margin-bottom: 25px;
    margin-left: 10px;

  }
  .tab-container__content
  {
    padding:0;
  }

  .subscription-plan-card {
      margin-left: auto;
      margin-right: auto;
    width:260px;
    min-height: 339px!important;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    background: #fff;
    border: 1px solid #e2e4e5;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 300ms ease-in-out forwards;
    transition: box-shadow 250ms ease-in-out;

    &:hover {
      box-shadow: 0 2px 12px 0 var(--color-secondary-purple);
      background: #fff;
    }

    &--active-plan {
      border: 1px solid var(--color-secondary-purple) !important;
      box-shadow: 0 2px 16px 0 var(--color-secondary-purple);
    }

    .subscription-plan {
      &__name,
      &__price {
        color: var(--color-primary-blue);
        text-align: center;
      }

      &__name {
        font-size: 22px;
        font-weight: 600;
      }

      &__price {
        margin-top: 20px;
        // font-size: 34px;
        // font-weight: 400;
        font-size: 18px;
        font-weight: 700;
        line-height: .96;
        // text-align: center;
        
      }
     

      &__feature {
        font-size: 14px;
      }
    }

    .input-button {
      padding: 0.65rem 0 !important;
      margin-top: auto !important;
    }
  }

  .team-member-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
    background: #fff;
    border: 1px solid #e2e4e5;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 300ms ease-in-out forwards;

    &.has-opened-action-menu {
      z-index: 100;
      position: relative;
    }
    .team-member {
      &__name,
      &__invite-status,
      &__user-type {
        flex: 1;
        display: inline-block;
      }

      &__name {
        font-size: 16px;
        margin-bottom: 0;
      }

      &__action-menu-button {
        cursor: pointer;

        &--disabled {
          filter: grayscale(0.78);
          pointer-events: none;
          cursor: initial !important;
        }
      }

      .dropdown-item {
        outline: none !important;
        background: transparent !important;
      }

      &__action-menu {
        button:active {
          outline: none !important;
          background: transparent !important;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.08) !important;
        }
      }
    }
  }

  .profile-image {
    pointer-events: none !important;

    .default-image-container__icon {
      display: none !important;
    }
  }

  .members-tab-content {
    // max-width: 1200px;
    .add-member-input-field {
      max-width: 768px;
      width: 100%;
    }
  }

  .react-bootstrap-table-pagination{
    margin: 0;
    justify-content: center;
  }
  .react-bootstrap-table-pagination .col-md-6:nth-of-type(1), .react-bootstrap-table-pagination .col-lg-6:nth-of-type(1){
   display: none;
  }
  .react-bootstrap-table-page-btns-ul
  {
    justify-content: center;
  }
}
.modelbody{
  display: flex;
  flex: 1;
  &_p{
    flex: 1;
  display: flex;
  padding: 5px;
  }
  &_select{
    flex: 1;
  }
}
.w-30
{
  width: 30%;
}
.page-item.active .page-link{
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 1rem;

}
.page-link{
  border:none;
}
a.page-link{
  color: #131415!important;
}
a.page-link:hover{
  display: block;
  border-radius:1rem!important;
  background-color:  rgba(0, 0, 0, 0.04)!important;
}
.page-link:focus {
box-shadow: none!important;
}
.InvoiceBilling{
  font-weight: 600;
  font-size: 15px;
}

.InvoiceTable 
{
  border-collapse:separate;
  border-spacing:0 15px;
}
.InvoiceTable tbody tr
{

background: #fff;
border: solid 0.5px rgba(216, 216, 216, 0.65);
border-radius: 5px;
box-shadow: 0 2px 4 0 rgba(0, 0, 0, 0.06);
transition: box-shadow 250ms ease-in-out
}
.InvoiceTable tbody td
{
  border-bottom: solid 1px rgba(216, 216, 216, 0.65);
  color: #333;
  word-wrap:break-word;
  font-size: 14px;
}
.highlightInvoice{
  font-weight: 500;
  color: #090763;
}
.invoice-tabel-div .react-bootstrap-table tr td{
  padding: 1rem .75rem!important;
}
li.disabled.page-item a.page-link{
  background-color: transparent!important;
  opacity: 0.38;
} a.page-link{
  background-color: transparent!important;
}
.invoice-tabel-div{
  padding: 1rem 0rem;
}
.tablediv .react-bootstrap-table tr td:first-child{
    padding-left: 0.75rem!important;
  }