.react-resizable {
    max-width: 100%;
  }
  
  .react-resizable-handle {
    bottom: -10px;
    right: -10px;
  }
  
  .lagRadar {
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
  }
  .chart-container {
   width: 400px;
   height: 400px;
}
 .line-chart{
   width: 500px;
   height: 500px;
 }