.global-search-page {
  padding: 0;

  &__top-section {
    padding: 2rem;
    padding-top: 1.5rem;
    background: #fff;

    .bg-world-map {
      background-position: 47vw -19vw !important;
      background-size: 75vw 75vw !important;
    }
  }

  .tab-container {
    &__header {
      background: #fff;
    }
  }

  .input-fields-container {
    max-width: 1200px;

    label {
      color: var(--color-primary-blue);
      font-size: 14px;
    }
  }

  .radio {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__input {
      --size: 16px;
      display: inline-block;
      margin-right: 0.5rem;
      width: var(--size);
      height: var(--size);
      background: #fff;
      border: 1px solid #807df8;
      border-radius: 555rem;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
      transition: background 250ms ease-in-out;

      &.active {
        background: #807df8;
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .accordian-card {
    & > .card {
      background: #e8e6f7;
      border: none;
    }

    .accordian-card__button {
      position: absolute;
      right: 0;
    }

    .accordian-card__event-count {
      text-align: right !important;
      font-weight: 600 !important;
    }

    .accordian-card__name,
    .accordian-card__event-count {
      font-weight: 500 !important;
      font-size: 15px !important;
      color: #090763 !important;
    }

    .accordian-card__uid,
    .accordian-card__gender,
    .accordian-card__relevance-score,
    .accordian-card__flag {
      font-weight: 400 !important;
      // font-size: 13px !important;
      color: #464546 !important;
    }

    .accordian-card__button-container .button:before {
      color: #090763 !important;
      font-size: 24px !important;

      @include filter-primary-blue;
    }

    &__toggle-button {
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }

    &__toggle-icon {
      font-size: 12px;
      color: #090763 !important;
    }
  }

  .event-small-card {
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
    background: #fffd;
    cursor: pointer;

    .blue-circle {
      position: relative;
      bottom: -7px;
    }

    &__event-name {
      font-weight: 500;
      font-size: 15px !important;
      color: #090763;
      opacity: 0.9;
    }

    &__event-uid,
    &__event-date {
      // font-weight: 500;
      color: #090763;
      opacity: 0.9;
      font-size: 14px !important;
    }

    &__read-more-button {
      padding: 0.25rem 1rem !important;
      font-size: 10px !important;
    }
  }
}

.event-details-popup {
  //max-width: calc(90vw - 1rem) !important;
  max-width: calc(70vw - 1rem) !important;
}

.modal.fade {
  left: 3.5rem;
}
.w-25px
{
  width: 25px;
}