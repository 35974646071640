// .resourceCard h5 {
//   color: #131415 !important;
//   font-size: 17px;
//   line-height: 25px;
// }

// .resources-page {
//   padding: 2rem;

//   &__top-section {
//     //padding: 2rem;
//     padding-top: 1.5rem;
//   }
// }
// .resources-page .site-page__header {
//   margin-bottom: 0;
//   //border-bottom: 1px solid rgba(19, 20, 21, 0.1);
//   padding-bottom: 10px;
//   padding-left: 15px;
// }
.resource-dot {
  --size: 7px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: rgba(19, 20, 21, 0.6);
}
.resultsCount{
  font-size: 14px!important;
}
.resources-page {
  padding: 25px;
  padding-bottom: 0;
 

}
.ResourcePage .ticker-card
{    cursor: default;
}
.ResourcePage:after {
  content: "";
  display: block;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgba(19, 20, 21, 0.1);
  margin: 10px auto;
  z-index: 9999;
}

.news_tag-bx {
  padding: 4px 12px;
  background: #e0dffe;
  border: 1px solid #807df8;
  border-radius: 20px;
  text-align: center;
  font-weight: normal;
  margin-right: 5px;
  margin-bottom: 3px;
}
.resourceCard h5 {
  color: #090763 !important;
  font-size: 15px;
  line-height: 25px;
}
.news-basic-info {
  color: rgba(19, 20, 21, 0.6);
  font-size: 13px;
}
.news-description {
  color: rgba(19, 20, 21, 0.6);
  font-size: 14px;
  margin-bottom: 10px;
}
.news-dot {
  --size: 7px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: rgba(19, 20, 21, 0.6);
}
.resourceCard .card-header {
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.resourceCard {
  background-color: #fff;
  border-radius: 8px;
}
.resourceCard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.ResourceAccordian {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}
.ResourceAccordian .card-header {
  padding: 0;
  border: none;
}
.ResourceAccordian .form-check-label {
  color: #131415;
  font-size: 16px;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.ResourceAccordian .btn {
  font-size: 15px;
  color: #555;
  font-weight: 500;
}
.ResourceAccordian .btn-link:hover {
  text-decoration: none;
}
.ResourceAccordian .card {
  border-radius: 0;
  border-right: none;
  border-left: none;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
  text-decoration: none;
}
.newsFilterHeading {
  color: #131415;
  padding-left: 12px;
  font-size: 17px;
  display: inline-block;
}
.ResourceAccordian .reset-button {
 float: right;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
.purpleBadge {
  background-color: #807df8;
  position: absolute;
  left: 0;
  top: 0;
  padding: 9px 9px;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 1px;
}
.filterImg {
  width: 16px;
  margin-right: 6px;
  vertical-align: bottom;
}
// ================***newsDetailModal***================

.aHover:hover {
  font-weight: 600;
}

.cntryHeight {
  height: 200px;
  overflow: auto;
}

.cntryHeight::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

.cntryHeight::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}

.cntryHeight::-webkit-scrollbar-thumb {
  background-color: #ddd;
  opacity: 0.5;
}
.w-15 {
  width: 20px;
}
.w-20 {
  width: 20px;
  height: 20px;
}
.newsfilterBadge {
  border-radius: 50%;
  font-size: 8px;
  padding: 3px 5px;
  text-align: center;
  margin-left: 4px;
}
//Timeline
.ResourceAccordian .btn-filter {
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  background: #f7f7f7 !important;
  border-radius: 0;
}
.ResourceAccordian .btn-filter .date-range-picker__toggle-button__text {
  font-size: 16px;
  margin-left: 0;
}
// .newsAccordian .btn-filter i {
//   display: none;
// }
.sourceImg
{
  width: 14px;
  height: 14px;
  margin-right: 7px;
  margin-bottom: 7px;

}
.bgwhite{
  background-color: #fff!important;
}
.regulcarddiv .tag-bx{ a{
    font-size: 14px;
    color: rgba(19, 20, 21, 0.6)!important;
    font-weight: 600;
    /* margin-top: -1px; */
    /* margin-bottom: 4px; */
    white-space: normal;
    cursor: unset;
    overflow-wrap: break-word;
}.resource-dot {
  --size: 7px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
}
}
.regulcarddiv .tag-bx a:hover{
  font-weight: 600!important;
}
.keywordscardDiv{
  .tag-bx{
    a{
      padding: 4px 8px!important;
      margin-right: 10px!important;
    }
  }
}