.image-slider {
  height: 350px;
  border: 1px solid var(--color-primary-blue);
  overflow: hidden;

  .carousel-inner {
    height: 100%;
  }

  .carousel-control-prev , .carousel-control-next {
      display: flex !important;
  }
}
