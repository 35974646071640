.site-pdf-reader {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  background-color: rgb(82, 86, 89);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;

  @media print {
    .pdf-container {
      display: none;
    }
  }

  .pdf-container {
    display: flex;
    justify-content: center;
    user-select: none;
  }

  .controls {
    margin: 20px;
    margin-top: 0;
    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    &__button {
      // background: none !important;
      // border: none!important;
      // outline: none !important;
      // color: var(--color-primary-blue);
      // font-size: 14px;
      // font-weight: 500;
    }
  }

  @media print {
    .pdf-container {
      display: none;
    }
  }

  .react-pdf__Page__annotations .annotationLayer {
    display: none;
  }
  

  .controls {
    text-align: center;
  }

  .react-pdf__Page__canvas {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-top: 1rem;
    block-size: 0;
  }
  
//   .react-pdf__Page__canvas {
//     margin: 0 auto;
//     width: 100% !important;
//     height: 100% !important;
// }
}
#ResumeContainer {
  margin:auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// .PDFPage {
//   box-shadow: 0 0 8px rgba(0, 0, 0, .5);
// }

.PDFPageOne {
  margin-bottom: 25px;
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}
// .react-pdf__Page__canvas{
//   box-shadow: 0 0 8px rgba(0, 0, 0, .5);
// }
.react-pdf__Page__canvas {
  //box-shadow: 0 0 8px rgba(0, 0, 0, .5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  //margin-top: 1rem;
  //block-size: 0;
}