.event-card-b {
  height: min-content;
  min-height: 8rem;
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
  border: solid 0.5px rgba(216, 216, 216, 0.65);
  background-color: #fff;

  &__event-name {
    font-size: 15px;
    color: var(--color-primary-blue);
  }

  &__flag-container{
      display: inline-block;
      width: 3rem;
      height: 2rem;
      transform: translateY(-2px);
  }

  &__flag-image{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }

  &__methods {
    font-size: 14px;
    font-weight: 300 !important;
  }

  &__read-more-button {
    padding: 0.25rem 1rem !important;
    font-size: 10px !important;
  }

  &__criticality-image {
    position: relative;
    top: -1px;
    max-width:16px;
  }
}
