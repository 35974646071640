:root {
  --color-primary-blue: #090763;
  --color-primary-blue-light: #364fcc;
  --color-secondary-purple: #807df8;
  --color-secondary-purple-light: #e0dffe;
  --color-black: #131415;
  --color-gray-light: #f9f9fb;
  --color-sky-blue: #007aff;

  --color-text: #5a5a5a;
  // #F6F6FE
  // --z-index-site-navigation-panel: 6000;
}

// ------------------------| FILTERS |------------------
@mixin filter-white {
  filter: brightness(0) invert(1) !important;
}

@mixin filter-black {
  filter: invert(8%) sepia(1%) saturate(1985%) hue-rotate(169deg)
    brightness(92%) contrast(98%);
}

@mixin filter-primary-blue {
  filter: invert(5%) sepia(77%) saturate(6261%) hue-rotate(241deg)
    brightness(101%) contrast(104%);
}

@mixin filter-secondary-purple {
  filter: invert(45%) sepia(97%) saturate(545%) hue-rotate(206deg)
    brightness(99%) contrast(97%);
}

@mixin filter-secondary-purple-light {
  filter: invert(78%) sepia(62%) saturate(264%) hue-rotate(197deg)
    brightness(103%) contrast(102%);
}

@mixin filter-gray-light {
  filter: invert(45%) sepia(0%) saturate(7417%) hue-rotate(315deg)
    brightness(136%) contrast(84%);
}

//-------------------------| COLOR UTIL CLASSES |--------------------------
.text-color {
  &-white {
    color: #fff !important;
  }

  &-gray-light {
    color: var(--color-gray-light) !important;
  }

  &-blue {
    color: var(--color-primary-blue) !important;
  }

  &-blue-light {
    color: var(--color-primary-blue-light) !important;
  }

  &-purple {
    color: var(--color-secondary-purple) !important;
  }
}

.bg-color {
  &-white {
    background: #fff !important;
  }

  &-gray-light {
    background: var(--color-gray-light) !important;
  }

  &-blue {
    background: var(--color-primary-blue) !important;
  }

  &-blue-light {
    background: var(--color-primary-blue-light) !important;
  }

  &-purple {
    background: var(--color-secondary-purple) !important;
  }
}

.border-color {
  &-white {
    border-color: #fff !important;
  }

  &-gray-light {
    border-color: var(--color-gray-light) !important;
  }

  &-blue {
    border-color: var(--color-primary-blue) !important;
  }

  &-blue-light {
    border-color: var(--color-primary-blue-light) !important;
  }

  &-purple {
    border-color: var(--color-secondary-purple) !important;
  }
}
