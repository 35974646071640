.event-detail-page {
  padding: 0;
  margin-top: 0.5rem;

  &__top-section {
    padding: 2rem;
    padding-top: 1.5rem;
    background: #fff;
  }

  .ticker-card {
    border: solid 1px #ebebeb;

    &__value {
      transform: translateY(-4px);
    }
  }

  .event-basic-info {
    &__item,
    .event-country-flag__container {
      display: inline-block;
    }

    .event-country-flag {
      &__container {
        width: 3rem;
        height: 1.8rem;
        transform: translateY(-2px);
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .tab-container {
    &__content,
    .tab-name {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__content {
    color: var(--color-text);
    min-height:40vh;
    background: #fff;
  }

  ul {
    margin-left: 1rem;
  }

  li {
    list-style: disc;
    margin-bottom: 0.5rem;
    color: var(--color-primary-blue);
  }

  .similar-events {
    background: #fff;
    padding: 2rem 0;

    .site-page__heading {
      padding: 0rem 2rem;
    }

    &__container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 0.5rem 2rem;

      .event-card {
        display: inline-flex;
        // min-width: 550px;
        margin-right: 2rem;
        cursor: pointer !important;
      }
    }

    &--new {
      background: #fff;
      // padding: 2rem 0;

      .site-page__heading {
        padding: 0rem 2rem !important;
      }

      &__container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding: 0.5rem 2rem;

        .event-card {
          display: inline-flex;
          min-width: 550px;
          margin-right: 2rem;
        }
      }
    }
  }

  .event-tags {

    padding: 1rem 2rem;
    //padding: 0 2rem !important;
    background: #fff;
    margin: 2rem 0;

    .tag-bx {
      padding: 5px 0;
      // padding: 0 2rem;
      // background: #fff;
      a {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        padding: 4px 11px;
        border: 1px solid #090763;
        border-radius: 40px;
        font-size: 10px;
        color: #090763 !important;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 5px;
        cursor: unset;
      }
    }

    .site-page__heading {
      // padding: 0rem 2rem !important;
      padding: 0 !important;
      margin-bottom: 1.5rem;
    }

    .mt-30 {
      margin-top: 30px;
    }

  }


  .event-map-card {

    padding: 1rem 2rem;
    background: #fff;
    margin: 2rem 0;

   .card {
      border-radius: 14px;
      // background: var(--color-primary-blue) !important;
      // color: #fff !important;

      background: #fff !important;
      color: #090763 !important;
    }

    .accordian-card__toggle-button::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        -webkit-transition: all .35s;
        transition: all .35s;
        color: #090763;

    }
//     tab-label::after {
//     content: "\276F";
//     width: 1em;
//     height: 1em;
//     text-align: center;
//     -webkit-transition: all .35s;
//     transition: all .35s;
// }

    span.input-field.form-group.fa-icon-cricle {
        margin: 0 5px;
    }

    .card-body {
      /* border-radius: 14.8px; */
      box-shadow: 0 12px 13px 0 rgba(0,0,0,.05);
      border: .5px solid rgba(205,208,227,.65);
      background-color: #fff !important;
      position: relative;
   }

   button.accordian-card__toggle-button.btn.btn-link {
      margin-left: auto;
      padding: 0;
      line-height: 1;
      font-size: 12px;
   }

  }


}


.map-mt {
  color: var(--color-text);
  background: #fff;
  width: 100%;
  height: 38px;
  display: block;
  margin: 2rem 0;
  padding: 5px 2rem;


    a, a:hover, a:active, a:visited {
      display: unset;
      color: unset;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
  }

}

.tab-container {
    font-size: 14px;
}

.event-details-popup {

  .event-detail-page {

    &__top-section {
      padding: 1rem;
    }

    .event-tags{
       padding: 0 1rem !important;
    }

    // .event-tags .tag-bx {
    //   padding: 0 1rem !important;
    // }

    // .event-tags .site-page__heading {
    //   padding: 0rem 1rem !important;
    // }

    &__content {
      min-height: 25vh;
    }

  }

  .site-page__header{
    font-size: 12px;
  }

  .site-page__heading{
    font-size: 15px;
  }

  .tab-container {
    &__content,
    .tab-name {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .fa-icon-cricle{
    color: #a395f9;
  }

}

.modal-content {
  border-radius: .4rem !important;
}

.event-cursor {
  cursor: pointer;
}
.shareEvents{
  .read-btn{
  color: #090763!important;
  font-size: 15px;
  font-weight: 600;
  }
  .read-btn:hover{
    text-decoration: underline;
  }
  .heightContainer{
    height: 484px;
  }
  .minheightnone{
    min-height: 5vh!important;
  }
  .ml-2rem{
    margin-left: 2rem;
  margin-top: 2rem;
margin-bottom: 2rem;  }
}
.event-map-card-share-events{
  padding: 0rem 1.2rem;
  background: #fff;
  margin-bottom: 2rem ;
}
.mousePointerCopyImg {
  cursor: pointer;
  height: 23px;
  width: 23px;
  margin-bottom: 10px;
}