.economic-data-info-card {
  padding: 1.5rem;
  border-radius: 15px;
  border: solid 0.5px #e8e8e8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  height: 100%;

  opacity: 0;
  transform: translateY(8px);
  animation: fade-in-rise-up 400ms ease-out forwards;

  &__name {
    font-size: 15px;
    font-weight: 700;
  }

  &__graph-container {
    border: 1px solid rgba(0, 0, 0, 0.03);
  }

  &__table-container {
    td,
    th {
      font-size: 14px;
      padding: 0.25 1rem;
    }
  }
}
