.pinned-page-box .plan-tab ul {
  list-style: none;
}

.pinned-page-box .plan-tab li {
  display: inline-block;
  padding: 4px 35px;
  cursor: pointer;
}

.pinned-page-box .plan-tab ul {
    list-style: none;
    margin: 0 0 25px 0px;
    padding: 0;
    display: inline-block;
}

.pinned-page-box .plan-tab ul li {
    float: left;
    border: 1px solid #eee;
    background: #dee0e2 !important;
    min-width: 156px;
}

.pinned-page-box .plan-tab ul li:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: none;
    text-align: center;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.pinned-page-box .plan-tab ul li:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}

.pinned-page-box .plan-tab li.active {
    color: #fff !important;
    background: #1b3272 !important;
    border: 1px solid #fff !important;
    padding: 5px 35px;
}
.pinned-page-box a {
  font-weight: 200 !important;
  color: #1b3272 !important;
}
.pinned-page-box .plan-tab li.active a {
  font-weight: 200 !important;
  color: #fff !important;
}

.pinned-page-box .pin-row {
  height: 65px;
  padding: 12px 0px;
  margin-bottom: 15px;
}

.pinned-page-box .shadow-pin {
  border-radius: 14.8px;
  box-shadow: 0 12px 13px 0 rgba(0,0,0,.05);
  border: .5px solid rgba(205,208,227,.65);
  background-color: #fff !important;
  position: relative;
}

.pinned-page-box input.form-control.search-input {
    padding: 0px 30px;
    height: 38px !important;
    border-radius: 5px;
    border: .5px solid rgba(205,208,227,.65);
}

.pinned-page-box .input-icons i {
   position: absolute;
}

.pinned-page-box label {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    color: #1b3272;
}
.pinned-page-box .form-control {
    font-size: .8125rem;
    font-weight: 400;
    height: 38px!important;
    border-radius: 5px;
    border: .5px solid rgba(205,208,227,.65) !important;
    // color: #b3b3b3 !important;
    // color: #716f6f !important;
}

.pinned-page-box .input-icons {
   width: 100%;
   margin-bottom: 10px;
}

.pinned-page-box .icon {
   padding: 10px;
   min-width: 40px;
   color: #cac3c3 !important;
}

.event-white-bg {
  background: #fff !important;
  border-radius: 14.8px;
}

.pinned-page-box .advanced-search-dropdown.mr-3 {
    width: 50%;
    display: inline-block;
}

.pinned-page-box .inline-selects {
    display: flex;
    margin-bottom: 25px;
}

.blue-circle {
    width: 8px;
    height: 8px;
    background: #090763 !important;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
}

.pinned-page-box .main-w {
    background: #fff !important;
    border-radius: 14.8px;
    box-shadow: 0 12px 13px 0 rgba(0,0,0,.05);
    border: .5px solid rgba(205,208,227,.65);
    background-color: #fff !important;
    padding: 15px;
    // margin-left: 15px;
    // margin-right: 15px;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.pinned-page-box .cont-design h4 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
}

.pinned-page-box .box-letlisting span {
  vertical-align: middle;
  font-weight: 600;
}

.pinned-page-box .date_l {
    color: #090763 !important;
    font-weight: 500;
    position: relative;
}

.pinned-page-box .r-rbold{
    color: #090763 !important;
    font-weight: 600;
}

.pinned-page-box .pin-img img{
  width: 23px;
}
.pinned-page-box .pin-img{
  margin: auto;
}

.pinned-page-box .box-letlisting {
    background: #fff !important;
    padding: 15px 0px 15px 6px;
    border-bottom: 1px solid #e4e4e4;
}

.pinned-page-box .box-letlisting h3 {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.pinned-page-box .box-letlisting p, .box-letlisting span {
    color: #b3b3b3 !important;
    font-size: 13px;
    margin-bottom: 0px;
}

.pinned-page-box .box-letlisting:hover{
   background-color:#dee0e2 !important;
}

.pinned-page-box .advanced-search-dropdown.mr-3:last-child{
   margin-right: 0px!important;
}

.pinned-page-box .pd-20 {
  padding: 20px;
  margin-right: -15px;
}

.pinned-page-box .ql-editor {
  min-height: 18em;
}

.pinned-page-box .fa-icon-cricle .fa {
  padding: 8px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  background: #e8dfdf !important;
  border-radius: 14.8px;
  box-shadow: 0 12px 13px 0 rgba(0,0,0,.05);
  border: .5px solid rgba(205,208,227,.65);
  background-color: #fff !important;
  margin: 0 2px;
  float: right;
  width: 32px;
  color:#1b3272 !important;
}

.pinned-page-box .fa-icon-cricle .fa:hover {
    opacity: 0.7;
}

.pinned-page-box .event-tag-bx{
    margin-top: 10px;
}

.pinned-page-box .event-tag-bx a, {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  padding: 4px 16px;
  border: 1px solid #dee0e2 !important;
  border-radius: 40px;
  font-size: 10px;
  font-weight: 600;
  background: #dee0e2 !important;
}

.pinned-page-box .event-span-text {
  color: gray;
  padding: 5px 0px;
  font-size: 13px;
}

.pinned-page-box .pt-event-20 {
   padding: 20px 0px;
}

.pinned-page-box .pt-event-20 div {
  font-size: 12px;
}

.pinned-page-box .dot3-icon {
  float: right;
}
.pinned-page-box .dot3-icon img {
  width: 29px;
  height: auto;
  border-radius: 50%;
  margin-top: -25px;
}

.pinned-page-box .dropdown {
  position: relative;
  display: inline-block;
  float: right;
}

.pinned-page-box .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9 !important;
  min-width: 115px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  z-index: 1;
  right: 0;
  margin-top: 8px;
}

.pinned-page-box .dropdown:hover .dropdown-content {
  display: block;
}

.pinned-page-box .dropdown-content a {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

.pinned-page-box .dropdown-content a:hover {background-color: #ddd !important;}

.pinned-page-box .dropdown:hover .dropdown-content {display: block;}

.pinned-page-box .dropdown:hover .dropbtn {background-color: #3e8e41 !important;}

.pinned-page-box .comman-select .advanced-search-dropdown.mr-3 {
    display: flex !important;
    width: auto !important;
    align-items: center;
}

.pinned-page-box .comman-select{
  padding-top: 20px;
}

.pinned-page-box .comman-select .css-2b097c-container {
    flex: 1 !important;
    width: 220px;
    margin-left: 3px;
    font-size: 14px !important;
}

.pinned-page-box .comman-select .css-yk16xz-control {
    border: none;
}

.pinned-page-box .comman-select span.css-1okebmr-indicatorSeparator {
    display: none;
}

.pinned-page-box .gray-clr{
  font-size: 12px;
  color: gray;
  margin-top: 6px;
}

.pinned-page-box .100-vh {
  min-height: 100vh !important;
}

.pinned-page-box .event-input {
  padding: 0px 10px;
  height: 38px !important;
  border-radius: 5px;
  border: 0.5px solid rgba(205, 208, 227, 0.65);
}

/** pinned event page css **/

/** floating button css **/
.adminActions {
  position: fixed;
  bottom: 65px;
  right: 35px;
}

.adminButton {
  height: 60px;
  width: 60px;
  background-color: rgba(67, 83, 143, .8);
  border-radius: 50%;
  display: block;
  color: #fff !important;
  text-align: center;
  position: relative;
  z-index: 1;
}

.adminButton i {
    font-size: 22px;
}

.adminButtons {
  position: absolute;
  width: 100%;
  bottom: 120%;
  text-align: center;
}

.adminButtons img {
  filter: brightness(0) invert(1) !important;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.adminButtons a {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-decoration: none;
  margin: 10px auto 0;
  line-height: 1.15;
  color: #fff !important;
  opacity: 0;
  visibility: hidden;
  position: relative;
  box-shadow: 0 0 5px 1px rgba(51, 51, 51, .3);
}

.adminButtons a:hover {
   transform: scale(1.05);
}

.adminButtons a:nth-child(1) {background-color: rgba(67, 83, 143, 0.8) !important; transition: opacity .2s ease-in-out .3s, transform .15s ease-in-out;}
.adminButtons a:nth-child(2) {background-color: rgba(67, 83, 143, 0.8) !important; transition: opacity .2s ease-in-out .25s, transform .15s ease-in-out;}
.adminButtons a:nth-child(3) {background-color: #f44336 !important; transition: opacity .2s ease-in-out .2s, transform .15s ease-in-out;}
.adminButtons a:nth-child(4) {background-color: #4CAF50 !important; transition: opacity .2s ease-in-out .15s, transform .15s ease-in-out;}

.adminActions a i {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.adminToggle {
  -webkit-appearance: none;
  position: absolute;
  border-radius: 50%;
  top: 0; left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 2;
  transition: box-shadow .2s ease-in-out;
  box-shadow: 0 3px 5px 1px rgba(51, 51, 51, .3);
}

.adminToggle:hover {
  box-shadow: 0 3px 6px 2px rgba(51, 51, 51, .3);
}

.adminToggle:checked ~ .adminButtons a {
  opacity: 1;
  visibility: visible;
}

.note-conainer {
  padding: 0;
}

.pin-page__top-section{
  padding: 2rem;
    padding-top: 1.5rem;
    background: #fff !important;
}

/** floating button css **/

.errorMsg {
  color: red;
  font-size: 12px;
  font-weight: 400 !important;
}
