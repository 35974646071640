@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes fade-in-rise-up {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}